import React from "react";

import Presentation from "./presentation";

const component = ( { title, classNames, buttonTextClassName, ...props } ) => {
	return (
		<Presentation
			classNames           = { classNames }
			title               = { title }
			{ ...props }
		/>
	);
};

export default component;