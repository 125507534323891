import BaseButton from "../button/baseButton";

import useStyles from "./styles";

const component = ( { title, className, buttonTextClassName, ...props } ) => {
	const { classes, cx } = useStyles ();

	return (
		<BaseButton
			buttonTextClassName = { buttonTextClassName }
			className = { cx ( classes.baseStyles, className ) }
			title = { title }
			variant = { "default" }
			{ ...props }
		/>
	);
};

export default component;
