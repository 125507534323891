import { Collapse }                      from "@mantine/core";
import { useScrollLock }                 from "@mantine/hooks";
import { useRouter }                     from "next/router";
import { Fragment, useEffect, useState } from "react";

import { storeEvent }                                       from "_analytics/index";
import { useGetIsSidebarMenuOpen, useSetIsSidebarMenuOpen } from "_common/hooks/global";
import { isCreatorDashboardPage, isPageRoute }              from "_common/utils";
import { getUtmParams }                                     from "_common/utils/seo";
import { PlainButton }                                      from "_components/atoms/button";
import Icon                                                 from "_components/atoms/icon";
import Typography                                           from "_components/atoms/typography";
import DashboardSidebar                                     from "_components/features/creatorDashboard/molecules/dashboardSidebar";
import ConfirmModal                                         from "_components/molecules/confirmModal";
import { useGetWebviewURL }                                 from "_jotai/index";
import useStyles                                            from "_styles/organisms/sidebarMenu";

import NavMenuItem     from "./components/navMenuItem/container";
import UserProfileCard from "./components/userProfileCard";

// import Skeleton                    from "_components/atoms/skeleton";

const component = ( {
	// liveFollowers,
	isSidebarMenuOpen,
	// recommendedList,
	// isLoadingRecommendedList,
	// isLoadingPopularCreators,
	// popularCreators,
	// isLoggedIn,
	isHomePage,
	isLivePage,
	isStudioPage,
	handleClick,
	footerList,
	gameZopLinks
} ) => {

	const { classes }        = useStyles ( { isSidebarMenuOpen }, { name: "sideBarMenu" } );
	const router             = useRouter ();
	const isCreatorDashboard = isCreatorDashboardPage ( { router } );
	const webviewURL         = useGetWebviewURL ();
	const toggleSidebarMenu  = useSetIsSidebarMenuOpen ();

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	const isReelsPage = checkPageRoute ( "reels" );

	/* const liveFollowerList = liveFollowers?.length ? (
    <CollapsableUserList
      isSidebarMenuOpen = { isSidebarMenuOpen }
      title = "Following"
      userList = { liveFollowers }
    />
  ) : null; */

	/* const popularCreatorUserList = popularCreators.length ? (
      <CollapsableUserList
        isSidebarMenuOpen = { isSidebarMenuOpen }
        title = { "Popular Creators" }
        userList = { popularCreators }
      />
    ) : null; */

	/* const recommendedUserList = (
    <UserList
      isLoading         = { isLoadingRecommendedList }
      isSidebarMenuOpen = { isSidebarMenuOpen }
      title             = { "Recommended" }
      userList          = { recommendedList }
    />
  );*/

	/* const footerCreatorsList = isLoggedIn ?
    (
      <>
        {liveFollowerList}

      </>
    )
    :
    popularCreatorUserList; */

	const onClickOverlay = () => {
		toggleSidebarMenu ( !isSidebarMenuOpen );
	};

	const redirectTo = href => {
		var a = document.createElement ( "a" );

		a.href = href;
		a.setAttribute ( "target", "_blank" );
		a.click ();
	};

	// useScrollLock ( isSidebarMenuOpen );

	return (
		<div
			className = { classes.container }
			style = { { width: isSidebarMenuOpen ? "250px" : "6rem" } }
		>
			{!isCreatorDashboard && (
				<div className = { classes.overlay }
					onClick = { onClickOverlay }
				/>
			)}

			<div className = { classes.contentContainer }>
				{!isCreatorDashboard ? (
					<Fragment>
						<div className = { classes.navMenuContainer }>
							<HomeMenuOption
								handleClick = { handleClick }
								isHomePage = { isHomePage }
								isStudioPage = { isStudioPage }
							/>

							{
								isStudioPage ? (
									<NavMenuItem
										active = { isStudioPage }
										iconMap = { { active: "dashboardFilled", inactive: "dashboardFilled" } }
										isSidebarMenuOpen = { isSidebarMenuOpen }
										name = "Dashboard"
										onClick = { () => { } }
									/>
								) : (
									<NavMenuItem
										active = { isLivePage }
										iconMap = { { active: "liveFilled", inactive: "liveInactive" } }
										isSidebarMenuOpen = { isSidebarMenuOpen }
										name = "Live"
										onClick = { () => handleClick ( "/live" ) }
									/>
								)
							}

							<ReelMenuOption
								active = { isReelsPage }
								handleClick = { handleClick }
								isStudioPage = { isStudioPage }
							/>

							{!isStudioPage ? (
								<NavMenuItem
									iconMap = { { active: "shop-bag", inactive: "shop-bag" } }
									isSidebarMenuOpen = { isSidebarMenuOpen }
									name = "Rooter Shop"
									onClick = { () => {
										storeEvent ( "shop", "shop_sidenav", "click", 1 );

										// redirectTo ( webviewURL );

										const params = getUtmParams ( { source: "side_nav", medium: "web", campaign: "organic" } );

										redirectTo ( `https://shop.rooter.gg/?${ params }` );
									} }
								/>
							) : null}

							{!isStudioPage ? (
								<NavMenuItem
									iconMap = { { active: "gameController", inactive: "gameController" } }
									isSidebarMenuOpen = { isSidebarMenuOpen }
									name = "Play Games"
									onClick = { () => {
										redirectTo ( gameZopLinks?.desktop );
									} }
								/>
							) : null}

						</div>

						{
							/* isStudioPage ?
                              null :
                              footerCreatorsList */
						}

						<div className = { classes.footerContainer }>
							{footerList.map ( item => (
								<a key = { item.title }
									href = { item.link }
									rel = "noreferrer"
									target = "_blank"
								>
									<Typography
										className = { classes.footerText }
										color = "secondary"
										title = { item.title }
									/>
								</a>
							)
							)}

							<Typography
								className = { classes.rooterText }
								color = "secondary"
								title = "© Rooter Sports Technologies Pvt. Ltd."
							/>
						</div>

					</Fragment>
				)
					: (
						<DashboardSidebar />
					)}
			</div>

		</div>
	);
};

export default component;

const HomeMenuOption = ( { isStudioPage, isHomePage, handleClick } ) => {
	const isSidebarMenuOpen = useGetIsSidebarMenuOpen ();

	if ( isStudioPage ) {
		return (
			<ConfirmModal
				closeAfterActionPreformed
				buttonComponent = { (
					<NavMenuItem
						active = { isHomePage }
						iconMap = { { active: "homeFilled", inactive: "homeInactive" } }
						isSidebarMenuOpen = { isSidebarMenuOpen }
						name = "Home"
					/>

				) }
				dialogText = "Are you sure you want to exit this page?"
				performAction = { () => {
					storeEvent ( "side_nav", "home" );
					handleClick ( "/" );
				} }
				rtmpPage = { true }
			/>
		);

	}

	return (
		<NavMenuItem
			active = { isHomePage }
			iconMap = { { active: "homeFilled", inactive: "homeInactive" } }
			isSidebarMenuOpen = { isSidebarMenuOpen }
			name = "Home"
			onClick = { () => {
				storeEvent ( "side_nav", "home" );
				handleClick ( "/" );
			} }
		/>
	);
};

const ReelMenuOption = ( { isStudioPage, active, handleClick } ) => {
	const isSidebarMenuOpen = useGetIsSidebarMenuOpen ();

	if ( isStudioPage ) {
		return null;
		/* return (
      <ConfirmModal
        closeAfterActionPreformed
        buttonComponent = { (
          <NavMenuItem
            active = { active }
            iconMap = { { active: "reelNavActiveIcon", inactive: "reelNavBarIcon" } }
            isSidebarMenuOpen = { isSidebarMenuOpen }
            name = "Reel"
          />

        ) }
        dialogText = "Are you sure you want to exit this page?"
        performAction = { () => {
          storeEvent ( "side_nav", "reel" );
          handleClick ( "/reels" );
        } }
        rtmpPage = { true }
      />
    ); */

	}

	return (
		<NavMenuItem
			active = { active }
			iconMap = { { active: "reelNavActiveIcon", inactive: "reelNavBarIcon" } }
			isSidebarMenuOpen = { isSidebarMenuOpen }
			name = "Reel"
			onClick = { () => {
				storeEvent ( "side_nav", "home" );
				handleClick ( "/reels" );
			} }
		/>
	);
};

/* const LoadingState = ( { isSidebarMenuOpen } ) => {
  const { classes }= useStyles ( );

  return (
    <div className = { classes.loaderContainer }>
      <Skeleton
        className = { classes.circulatSkeleton }
        height = { 35 }
        variant = "circular"
        width = { 35 }
      />

      {isSidebarMenuOpen && (
        <Skeleton
          className = { classes.rectangularSekleton }
          sx = { { fontSize: "1.5rem" } }
          variant = "subtle"
        />
      ) }
    </div>
  );
};*/

/* const UserList = ( { isSidebarMenuOpen, title="", userList=[], isLoading } ) => {
  const { classes }= useStyles ( { isSidebarMenuOpen } );

  if ( isLoading ) {
    return Array ( 10 ).fill ( 1 ).map ( ( _, key ) => (
      <LoadingState
        key = { key }
        isSidebarMenuOpen = { isSidebarMenuOpen }
      />
    ) );
  }

  if ( !userList.length ) return null;

  return (
    <div className = { `${ classes.recommendedContainerExpanded } ` }>
      <Typography
        className = { `${ classes.menuName } ${ classes.cardTitle }` }
        color = { "secondary" }
        style = { {
          opacity: isSidebarMenuOpen ? "1" : "0"
        } }
        title = { title }
      />

      <div className = { classes.cardContainer }>
        {userList.map ( user => {
          return (

            <UserProfileCard
              key = { user.id }
              data              = { user }
              isSidebarMenuOpen = { isSidebarMenuOpen }
            />

          );
        } )}

      </div>

    </div>
  );
};*/

const CollapsableUserList = ( { isSidebarMenuOpen, userList = [], title } ) => {
	const { classes } = useStyles ( { isSidebarMenuOpen } );

	const [ showMore, setShowMore ] = useState ( false );

	const showIcons = isOpen => {
		if ( !isOpen ) return (
			<Icon sameInBothTheme
				className = { classes.showIconStyle }
				name = "forwardArrowIconUp"
			/>
		);

		return (
			<Icon sameInBothTheme
				className = { classes.showIconStyle }
				name = "forwardArrowIconDown"
			/>
		);
	};

	useEffect ( () => {
		if ( !isSidebarMenuOpen ) setShowMore ( false );
	}, [ isSidebarMenuOpen ] );

	return (
		<div className = { `${ isSidebarMenuOpen ? classes.liveFollowersContainerExpanded : classes.liveFollowersContainerExpanded } ` }>
			<Typography
				className = { `${ classes.menuName } ${ classes.cardTitle }` }
				color = { "secondary" }
				style = { {
					opacity: isSidebarMenuOpen ? "1" : "0"
					// display : isSidebarMenuOpen ? "block" : "none"
				} }
				title = { title }
			/>

			<div className = { classes.cardContainer }>
				{userList.slice ( 0, 5 ).map ( follower => {
					return (
						<UserProfileCard
							key = { follower.id }
							data = { follower }
							isSidebarMenuOpen = { isSidebarMenuOpen }
						/>
					);
				} )}

				<Collapse in = { showMore }>
					{userList.slice ( 5, userList.length ).map ( follower => {
						return (
							<UserProfileCard
								key = { follower.id }
								data = { follower }
								isSidebarMenuOpen = { isSidebarMenuOpen }
							/>
						);
					} )}

				</Collapse>
			</div>

			{
				userList.length > 5 ? (
					isSidebarMenuOpen ? (
						<PlainButton
							buttonTextClassName = { classes.showMoreButtonText }
							className = { classes.showMoreButton }
							classNames = { { root: classes.showMoreButton } }
							onClick = { () => {
								storeEvent ( "side_nav", "more_profile" );
								setShowMore ( prevState => !prevState );
							} }
							rightIcon = { showIcons ( showMore ) }
							title = { `Show ${ showMore ? "less" : "more" }` }
							variant = "subtle"
						/>
					) : (
						<button className = { classes.arrowIconBtn }
							onClick = { () => {
								storeEvent ( "side_nav", "more_profile" );
								setShowMore ( prevState => !prevState );
							} }
						>
							<Icon
								className = { classes.arrowIcon }
								name = { showMore ? "arrowUpIcon" : "arrowDownIcon" }
							/>
						</button>
					)
				) : null
			}

		</div>
	);
};
