import React, { forwardRef } from "react";

import UserInfoCard from "./userInfoCard/container";

const component = forwardRef ( ( props, ref ) => {
	const { className, style, attributes, profile, handleHoverEnter, handleHoverExit } = props;

	return (
		<div ref = { ref }
			className = { className }
			style = { style }
			{ ...attributes?.popper }
			onMouseEnter = { handleHoverEnter }
			onMouseLeave = { handleHoverExit }
		>
			<UserInfoCard profile = { profile } />
		</div>
	);
} );

component.displayName = "presentationComponent";

export default component;
