import { useRouter } from "next/router";

import { storeCTEvent }          from "_analytics/index";
import { createURLSearchParams } from "_common/webview";
import BackgroundWrapper         from "_components/atoms/backgroundWrapper";
import Icon                      from "_components/atoms/icon";
import ToolTip                   from "_components/atoms/tooltip";
import Typography                from "_components/atoms/typography";

import useStyles from "./styles";

const DashboardSidebar = ( { isMobile } ) => {
	const router      = useRouter ();
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const handleItemClick = ( path, name ) => {
		const ctOptions = {
			eventProperty : "navigated_to",
			value         : name
		};

		storeCTEvent ( "creator_dashboard_navigation", ctOptions );
		router.push ( path );
	 };

	const params = createURLSearchParams ( router.query ); // handling webview URL

	const dashboardIcons = [
		{
			isSidebarMenuOpen : true,
			name              : "Overview",
			path              : [ `/creator-dashboard/overview${ params }`, `/creator-dashboard${ params }` ],
			iconMap           : { active: "overviewIconFilled", inactive: "overviewIcon" },
			mobileMenuName    : "Overview"
		},
		{
			isSidebarMenuOpen : true,
			name              : "Content",
			path              : [ `/creator-dashboard/content${ params }` ],
			iconMap           : { active: "contentIconFilled", inactive: "contentIcon" },
			mobileMenuName    : "Content"
		},
		{
			isSidebarMenuOpen : true,
			name              : "Channel Analytics",
			path              : [ `/creator-dashboard/channel-analytics${ params }` ],
			iconMap           : { active: "analyticsIconFilled", inactive: "analyticsIcon" },
			mobileMenuName    : "Analytics"
		},
		{
			isSidebarMenuOpen : true,
			name              : "Earning",
			path              : [ `/creator-dashboard/earning${ params }` ],
			iconMap           : { active: "earningIconBlue", inactive: "earningIcon" },
			mobileMenuName    : "Earn"
		}
	];

	return (
		<BackgroundWrapper
			className = { isMobile ? classes.navBar : classes.sidebarRoot }
			type = { "secondary" }
		>
			{
				dashboardIcons.map ( ( item, index ) => {
					return (
						<NavMenuItem
							key               = { index }
							active            = { item.path.includes ( router.asPath ) }
							iconMap           = { item?.iconMap }
							isMobile          = { isMobile }
							isSidebarMenuOpen = { item?.isSidebarMenuOpen }
							mobileMenuName    = { item.mobileMenuName }
							name              = { item?.name }
							onClick           = { () => handleItemClick ( item?.path?.[0], item.name ) }
							showText          = { !isMobile }
						/>
					);
				} )
			}
		</BackgroundWrapper>
	);
};

export default DashboardSidebar;

export const NavMenuItem = ( { active, iconMap, showText = true, onClick, name, isSidebarMenuOpen, isMobile, mobileMenuName } ) => {
	const { classes } = useStyles ( { isSidebarMenuOpen, active } );

	const navMenu = () => {
		return (
			<div className = { `${ active ? isMobile ? classes.mobile : classes.menuItemContainerActive : classes.menuItemContainer }` }>
				<Icon
					sameInBothTheme
					className = { classes.menuItem }
					name = { iconMap[active ? "active" : "inactive"] }
				/>

				{showText && (
					<Typography
						className = { classes.menuName }
						color = { active ? "primary" : "secondary" }
						title = { name }
					/>
				)}

			</div>
		);
	};

	if ( isMobile )
		return (
			<div onClick = { onClick }>
				{navMenu ()}

				<Typography
					className = { classes.mobileMenuName }
					title = { mobileMenuName }
				/>
			</div>
		);

	return (
		<div onClick = { onClick }>
			<ToolTip title = { name }>
				{navMenu ()}
			</ToolTip>
		</div>
	);
};