import React, { useEffect, useState } from "react";

import { useGetLoggedIn, useGetProfileData }      from "_common/hooks/auth";
import { useSetIsFollowStatus, useSetLoginPopup } from "_common/hooks/global";
import Toast                                      from "_common/toast";
import { getUserProfile, toggleFollowUser }       from "_services/api/user";

export default function FollowUser ( {
	userId,
	fromFeedElements,
	followComponent,
	unfollowComponent,
	isFollowing,
	userName,
	from,
	fromHomePage,
	handleGAEvent = () => { }
} ) {
	const [ isFollow, setIsFollow ]     = useState ( isFollowing );
	const [ iconStatus, setIconStatus ] = useState ( true );
	// const [ isLoading, setIsLoading ]   = useState ( false );

	const setLoginPopup      = useSetLoginPopup ();
	const ownProfile         = useGetProfileData ();
	const isLoggedIn         = useGetLoggedIn ();
	const toggleFollowStatus = useSetIsFollowStatus ();

	const getFollowStatusOfUser = async () => {
		// setIsLoading ( true );
		const userProfileParams = {
			isFollowing: true
		};

		const payload = {
			userId
		};

		const userProfile = await getUserProfile ( payload, userProfileParams );

		if ( userProfile && userProfile.isFollowing && userProfile.isFollowing.following )
			setIsFollow ( true );

		// setIsLoading ( false );

	};

	useEffect ( () => {
		if ( !isFollow ) {
			return;
		}

		setTimeout ( () => {
			if ( fromFeedElements ) {
				setIconStatus ( false );
			}
		}, [ 2000 ] );

	}, [ isFollow ] );

	useEffect ( () => {
		if ( ( isFollowing === undefined || parseInt ( isFollowing ) === 0 ) && isLoggedIn && ownProfile && ownProfile.id !== userId && userId ) {
			getFollowStatusOfUser ();
		}
	}, [ userId ] );

	useEffect ( () => {
		setIsFollow ( isFollowing );
		toggleFollowStatus ( isFollowing );
	}, [ isFollowing ] );

	const handleFollowUser = async () => {
		if ( isLoggedIn ) {
			if ( isFollow ) {
				const followPayload = {
					userId,
					action: "UNFOLLOW"
				};

				try {
					await toggleFollowUser ( followPayload );
					handleGAEvent ( "Unfollow" );

					Toast.info ( `Unfollowed ${ userName }` );
					setIsFollow ( false );
					toggleFollowStatus ( false );
				} catch ( err ) {
					Toast.error ( err.displayMsg );
				}
			} else {
				const followPayload = {
					userId,
					action: "FOLLOW"
				};

				try {
					await toggleFollowUser ( followPayload );
					handleGAEvent ( "Follow" );

					Toast.info ( `Followed ${ userName }` );
					setIsFollow ( true );
					toggleFollowStatus ( true );
				} catch ( err ) {
					Toast.error ( err.displayMsg );
				}
			}
		} else {
			setLoginPopup ( true );
		}
	};

	if ( !iconStatus )
		return null;

	let ownProfileId = ownProfile && ownProfile.id;

	const showFollowButton = !ownProfileId || ( ownProfileId && ownProfileId !== userId ) || !isLoggedIn;

	if ( !showFollowButton )
		return null;

	const isAlreadyFollowingUser = isLoggedIn && isFollow;

	const renderingComponent = isAlreadyFollowingUser ? unfollowComponent : followComponent;

	return (
		<div onClick = { handleFollowUser }
			style = { { width: from === "userInfoPopOver" ? "48%" : fromHomePage ? "100%" : "" } }
		>
			{renderingComponent}
		</div>
	);
}
