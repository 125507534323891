import Link from "next/link";

import Icon       from "_components/atoms/icon";
import Image      from "_components/atoms/image";
import Typography from "_components/atoms/typography";
import Button     from "_components/atoms/unstyledButton";
import useStyles  from "_styles/molecules/chip";

const Chip = ( {
	id,
	name,
	image,
	buttonStyles,
	clickAction,
	chipContentStyles,
	gameImageStyles,
	gameNameStyles,
	href,
	buttonInnerStyles,
	leftIcon
} ) => {

	const cursorType  = id && name ? "pointer" : "default";
	const { classes } = useStyles ( { cursor: cursorType }, { name: "/" } );

	const chipContent = (
		<div className = { `${ classes.chipContentStyles } ${ chipContentStyles }` }>
			{
				image ? (
					<Image className = { `${ classes.gameImage } ${ gameImageStyles }` }
						src = { image }
					/>
				) : null
			}

			<Typography
				className = { `${ classes.gameName } ${ gameNameStyles }` }
				component = "div"
				title = { name }
			/>

		</div>
	);

	if ( href ) {
		return (
			<Button
				className = { `${ classes.buttonStyles } ${ buttonStyles }` }
				leftIcon = { leftIcon }
				onClick = { clickAction }
				radius = "md"
				size = "lg"
			>
				<Link
					href = { href }
					prefetch = { false }
				>
					{chipContent}
				</Link>
			</Button>
		);
	}

	return (
		<Button
			className = { `${ classes.buttonStyles } ${ buttonStyles }` }
			classNames = { { inner: buttonInnerStyles } }
			leftIcon = { leftIcon }
			onClick = { clickAction }
			radius = "md"
			size = "lg"
		>
			{chipContent}
		</Button>
	);
};

export const ProChip = ( { onClick = null, title = "Pro", showShine = true } ) => {
	const { classes } = useStyles ( { background: title === "Pro Creator" ? "white" : "gray" }, { name: "proChannelDropdown" } );

	return (
		<Chip
			buttonInnerStyles = { classes.proChipContentStyles }
			buttonStyles = { classes.proButtonChip }
			leftIcon = { showShine ? (
				<Icon
					sameInBothTheme
					name = "proShine"
					size = { 15 }
				/>
			) : null }
			name = { title }
			onClick = { onClick }
		/>
	);
};

export default Chip;
