import Presentation from "./presentation";

const component = ( {
	id,
	name = "",
	image = "",
	buttonStyles = "",
	chipContentStyles = "",
	gameImageStyles = "",
	gameNameStyles = "",
	href = "",
	buttonInnerStyles = "",
	leftIcon,
	onClick
} ) => {

	return (
		<Presentation
			buttonInnerStyles = { buttonInnerStyles }
			buttonStyles = { buttonStyles }
			chipContentStyles = { chipContentStyles }
			clickAction = { onClick }
			gameImageStyles = { gameImageStyles }
			gameNameStyles = { gameNameStyles }
			href = { href }
			id = { id }
			image = { image }
			leftIcon = { leftIcon }
			name = { name }
		/>
	);
};

export default component;
