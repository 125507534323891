import { createStyles } from "@mantine/emotion";

export default createStyles ( () => {
	return {
		baseStyles: {
			height     : "unset",
			lineHeight : "unset",
			background : "none",
			border     : "none",
			padding    : "1rem",
			cursor     : "pointer"
		}
	};
} );
